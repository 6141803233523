import React from 'react'
import moreIcon from '../../icons/icons8-arrow-96.png';
import './studenttool.css'

const StudentTool = () => {
    return (
        <div className='student-resources'>

            <div className="resources-header">
                <h1>Study Resources</h1>
                <p>A diverse array of learning materials to enhance your educational journey.</p>
            </div>

            <div className="resources-grid">

                <div className="resource-card bg-notes bg-pattern-1">
                    <div className="resource-title">
                        <h1>Notes</h1>
                        <img src={moreIcon} width={20} height={20} alt="More Icon" />
                    </div>
                    <p>Access detailed study materials that simplify complex ideas into easily understandable language.</p>
                </div>

                <div className="resource-card bg-reference bg-pattern-2">
                    <div className="resource-title">
                        <h1>Reference Books</h1>
                        <img src={moreIcon} width={20} height={20} alt="More Icon" />
                    </div>
                    <p>Explore thorough study guides crafted by experts to help you grasp challenging concepts with ease.</p>
                </div>

                <div className="resource-card bg-ncert bg-pattern-3">
                    <div className="resource-title">
                        <h1>NCERT Solutions</h1>
                        <img src={moreIcon} width={20} height={20} alt="More Icon" />
                    </div>
                    <p>Find expertly curated solutions to NCERT problems designed to support academic excellence.</p>
                </div>

            </div>
        </div>
    );
};

export default StudentTool