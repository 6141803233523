import React from 'react';
import './about.css';
import img1 from '../../img/img10.jpg';
import img2 from '../../img/img12.jpg';
import img3 from '../../img/img13.jpg';
import imgeow from '../../img/our_vision_dtfuxk1.jpg'
import tech from '../../icons/technology_gfvzrw.webp'
import care from '../../icons/care_fe9uww.webp'
import access from '../../icons/accessibility_o9r0z0.webp'
import comm from '../../icons/community_h4dd80.webp'
import { useNavigate } from 'react-router-dom';

const About = () => {

    const nav = useNavigate();
    return (
        <div className="about-sections">
            <div className="about-content-wrappers">

                <div className="about-text-contents">
                    <h1>At Vidya Ashram,</h1>
                    <br />
                    <span className="about-subtitles">we solve problems</span>
                    <p className="about-descriptions">
                        Wherever you are, Vidya Ashram is there for you. Study in Hajipur, study at a centre near you.
                    </p>
                    <div className="about-buttons">
                        <button className="btn-enrolls" onClick={()=>{
                            nav('/contact')
                        }}>Enroll Now</button>
                        <button className="btn-gallerys" onClick={()=>{
                            nav('/gallery')
                        }}>Gallery</button>
                    </div>
                </div>


                <div className="about-image-sliders">
                    <div className="slider-tracks">
                        <img src={img2} alt="Image 1" />
                        <img src={img1} alt="Image 2" />
                        <img src={img3} alt="Image 3" />
                    </div>
                </div>

            </div>

            <div className="img-fix">
                <img src={imgeow} alt="" />
            </div>

            <div className="nm-sec">
                <h1>We Provide</h1>
                <p>We’re transforming how India learns</p>
                <div className="nm-boxs">

                    <div className="nm-box">
                        <img src={tech} alt="" />
                        <h3>Technology</h3>
                        <p>Solving the pain points of teachers & students, through AI-powered technology, making learning seamless.</p>
                    </div>
                    <div className="nm-box">
                        <img src={care} alt="" />
                        <h3>Holistic Care</h3>
                        <p>Being a support system for students beyond academics, nurturing their emotional and mental well-being.</p>
                    </div>
                    <div className="nm-box">
                        <img src={access} alt="" />
                        <h3>Accessibility</h3>
                        <p>Breaking barriers to quality education. Every student, regardless of their location, deserves educational excellence.</p>
                    </div>
                    <div className="nm-box">
                        <img src={comm} alt="" />
                        <h3>Community</h3>
                        <p>Creating a community of learners, nurturing a feeling of belonging that drives each person towards success.</p>
                    </div>
                    

                </div>
            </div>

        </div>
    );
};

export default About;
