import React from 'react'
import './admission.css'

const Admission = () => {
  return (
    <div className='admission'>
        <div className="admission-open-banner">
            
        </div>
    </div>
  )
}

export default Admission