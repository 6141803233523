import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import './contact.css';
import app from './Firebase';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber:  '',
        message: '',
    });

    const db = getFirestore(app); // Initialize Firestore

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleLog = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'messages'), formData);
            alert('Thanks For Submiting');
            setFormData({
                name: '',
                email: '',
                phoneNumber: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting message:', error);
            alert('Sorry for trouble. Your message is not delivered');
        }
    };

    return (
        <div className='contact-page'>

            <div className="contact-box-info">
                <h2>Vidya Ashram School</h2>
                <h3>Vidya Ashram Classes, Near Durga Mandir,<br /> Lalganj Road, Bagmali, Bagmali, Hajipur - 844101</h3>
                <p><span>Telephone: </span>9876543211</p>
                <p><span>Email: </span>admin@vidyaashram.com</p>
                <p><span>Website: </span>www.vidyaashram.com</p>
            </div>

            <div className="contact-form">
                <form onSubmit={handleLog}>
                    <h1>Contact Us</h1>
                    <input 
                        type="text" 
                        name="name" 
                        value={formData.name} 
                        placeholder='Name' 
                        onChange={handleChange} 
                        required 
                    />
                    <input 
                        type="email" 
                        name="email" 
                        value={formData.email} 
                        placeholder='Email ID' 
                        onChange={handleChange} 
                        required 
                    />
                    <input 
                        type="tel" 
                        name="phoneNumber" 
                        value={formData.phoneNumber} 
                        placeholder='Mobile No.' 
                        onChange={handleChange} 
                        required 
                    />
                    <textarea 
                        name="message" 
                        value={formData.message} 
                        placeholder='Message' 
                        onChange={handleChange} 
                        required 
                    ></textarea>
                    <input type="submit" value="Submit" />
                </form>
            </div>
        </div>
    );
};

export default Contact;
