import React, { useEffect, useState } from 'react';
import './home.css';
// import imgHero from '../img/Group 7.png';
// import imgLogo from '../img/th.jpg';
// import imgHero2 from '../img/Group 7 - Copy.png';
import vidyaAssram from '../img/bg-img1.jpg';
import vidyaAssramMobile from '../img/bg-imgformobile.jpg';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const nav = useNavigate();
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  })
  return (
    
    <div className="home-page">
      <div className="hero-section">

        {isMobile ?
          (<img src={vidyaAssramMobile} alt="Hero Image" className="hero-image" />)
          :
          (<img src={vidyaAssram} alt="Hero Image" className="hero-image" />)
        }

      </div>


      <div className="announcement-banner">
        <div className="announcement-content">
          <span>Admission Now Open! Enroll Today!</span>
          <span>Admission Now Open! Enroll Today!</span>
          <span>Admission Now Open! Enroll Today!</span>
          <span>Admission Now Open! Enroll Today!</span>
        </div>
      </div>

      <div className="about-section">
        <div className="admission-box">
          <p className="admission-title" onClick={() => { nav('/admission') }}>Admission Open</p>
          <button className="admission-button">Apply Now</button>
        </div>

        <div className="about-details">
          <h1 className="school-name">Vidya Ashram School</h1>
          <p className="school-description">
            At Vidya Ashram, we are a vibrant community shaping future leaders. With dedicated faculty and a
            supportive environment, we empower students to excel academically and personally. Explore the opportunities
            awaiting your child!
          </p>
        </div>
      </div>

      <div className="statistics-section">
        <h1 className="statistics-heading">Trusted by Many Across the Region</h1>
        <p className="statistics-subheading">
          Don't Just Take Our Word for It. Delve into the Numbers and Witness the Excellence for Yourself!
        </p>

        <div className="statistics-cards">
          <div className="statistics-card bg-cream">
            <h1 className="statistics-number">2000+</h1>
            <p className="statistics-label">Students</p>
          </div>
          <div className="statistics-card bg-pink">
            <h1 className="statistics-number">60+</h1>
            <p className="statistics-label">Teachers</p>
          </div>
          <div className="statistics-card bg-light-blue">
            <h1 className="statistics-number">28+</h1>
            <p className="statistics-label">Staff Members</p>
          </div>
          <div className="statistics-card bg-lavender">
            <h1 className="statistics-number">23+</h1>
            <p className="statistics-label">Curriculum</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
