import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './navbar.css';
import schoolLogo from '../img/vid-img.png';
import menuIcon from '../icons/navigation-bar.png';
import closeMenuIcon from '../icons/close.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLinkClick = () => {
        setIsMenuOpen(false);
        window.scrollTo(0, 0); // Scroll to top on routing
    };

    const nav = useNavigate();

    return (
        <div className="navbar">
            {/* Desktop Navigation */}
            <div className="navbar-desktop">
                <div className="navbar-logo">
                    <Link to="/" onClick={handleLinkClick}>
                        <img src={schoolLogo} alt="School Logo" />
                    </Link>
                </div>
                <nav className="navbar-links">
                    <ul>
                        <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
                        <li><Link to="/about-us" onClick={handleLinkClick}>About</Link></li>
                        {/* <li><Link to="/admission" onClick={handleLinkClick}>Admission</Link></li> */}
                        {/* <li><Link to="/facilities" onClick={handleLinkClick}>Facilities</Link></li> */}
                        <li><Link to="/gallery" onClick={handleLinkClick}>Gallery</Link></li>
                        <li><Link to="/student-tools" onClick={handleLinkClick}>Student Tools</Link></li>
                        {/* <li><Link to="/alumni" onClick={handleLinkClick}>Alumni</Link></li> */}
                        <li><Link to="/contact" onClick={handleLinkClick}>Contact</Link></li>
                    </ul>
                    {/* <button onClick={() =>{nav('/login')}} className="navbar-btn-login">Login</button> */}
                </nav>
            </div>

            {/* Mobile Navigation */}
            <div className="navbar-mobile">
                <div className="navbar-mobile-header">
                    <div className="navbar-logo">
                        <Link to="/" onClick={handleLinkClick}>
                            <img src={schoolLogo} alt="School Logo" />
                        </Link>
                    </div>
                    <div className="navbar-menu-controls">
                        {/* <button onClick={() =>{nav('/login')}} className="navbar-btn-login">Login</button> */}
                        <button className="navbar-btn-menu" onClick={toggleMenu}>
                            <img
                                src={isMenuOpen ? closeMenuIcon : menuIcon}
                                height={24}
                                width={26}
                                alt="Menu Icon"
                            />
                        </button>
                    </div>
                </div>

                {/* Slide Menu */}
                {isMenuOpen && (
                    <div className="navbar-slide-menu">
                        <ul>
                            <li><Link to="/" onClick={handleLinkClick}>Home</Link></li><hr />
                            <li><Link to="/about-us" onClick={handleLinkClick}>About</Link></li><hr />
                            {/* <li><Link to="/admission" onClick={handleLinkClick}>Admission</Link></li><hr /> */}
                            {/* <li><Link to="/facilities" onClick={handleLinkClick}>Facilities</Link></li><hr /> */}
                            <li><Link to="/gallery" onClick={handleLinkClick}>Gallery</Link></li><hr />
                            <li><Link to="/student-tools" onClick={handleLinkClick}>Student Tools</Link></li><hr />
                            {/* <li><Link to="/alumni" onClick={handleLinkClick}>Alumni</Link></li><hr /> */}
                            <li><Link to="/contact" onClick={handleLinkClick}>Contact</Link></li><hr />
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
