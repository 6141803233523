import React from 'react'
import './gallery.css'
import nexticon from '../../img/abr.png'
import gal1 from '../../img/gal1.jpg'
import gal2 from '../../img/gal2.jpg'
import gal4 from '../../img/gal4.jpg'
import gal5 from '../../img/gal5.jpg'
import gal6 from '../../img/gal6.jpg'
import gal7 from '../../img/gal7.jpg'
import gal8 from '../../img/gal8.jpg'
import gal9 from '../../img/gal9.jpg'
import gal10 from '../../img/gal10.jpg'
import gal11 from '../../img/gal1.jpg'
import gal12 from '../../img/gal2.jpg'
import gal13 from '../../img/gal13.jpg'
import gal14 from '../../img/gal4.jpg'
import gal15 from '../../img/gal15.jpg'
import gal16 from '../../img/gal16.jpg'
import gal17 from '../../img/gal17.jpg'
import gal18 from '../../img/gal18.jpg'
import gal19 from '../../img/gal19.jpg'
import gal20 from '../../img/gal20.jpg'
import gal21 from '../../img/gal21.jpg'
import gal22 from '../../img/gal22.jpg'
import gal23 from '../../img/gal23.jpg'
import img13 from '../../img/img13.jpg'


const Gallery = () => {
  return (
    <div className='gallery'>
      <section>

        <div className="grid-wrap">
          <div className=''>
            <img src={gal1} alt="" />
          </div>
          <div className='tall'>
            <img src={gal2} alt="" />
          </div>
          <div className=''>
            <img src={gal4} alt="" />
          </div>
          <div className=''>
            <img src={gal5} alt="" />
          </div>
          <div className=''>
            <img src={gal6} alt="" />
          </div>
          <div className=''>
            <img src={gal7} alt="" />
          </div>

          <div className=''>
            <img src={gal9} alt="" />
          </div>
          <div className='tall'>
            <img src={gal8} alt="" />
          </div>
          <div className=''>
            <img src={gal10} alt="" />
          </div>
          <div className=''>
            <img src={gal11} alt="" />
          </div>
          <div className=''>
            <img src={gal13} alt="" />
          </div>
          <div className=''>
            <img src={gal14} alt="" />
          </div>
          <div className=''>
            <img src={gal15} alt="" />
          </div>
         
          <div className='tall'>
            <img src={gal17} alt="" />
          </div>
          <div className=''>
            <img src={gal16} alt="" />
          </div>
          <div className='wide'>
            <img src={gal19} alt="" />
          </div>
          <div className=''>
            <img src={gal20} alt="" />
          </div>
          
          
          <div className='wide'>
            <img src={gal22} alt="" />
          </div>
          <div className='tall'>
            <img src={gal18} alt="" />
          </div>
          <div className=''>
            <img src={gal21} alt="" />
          </div>
          <div className='tall'>
            <img src={gal23} alt="" />
          </div>
          <div className=''>
            <img src={img13} alt="" />
          </div>
          
        </div>

      </section>
    </div>
  )
}

export default Gallery