import React from 'react'
import './footer.css'
import vector1 from '../img/Vector 1.png'
import schoolLogo from '../img/vid-img.png'
import schoolName from '../img/schoolname.png'
import { Link } from 'react-router-dom'
import icon1 from '../icons/img.icons8 1.png'
import icon2 from '../icons/img.icons8 2.png'
import icon3 from '../icons/img.icons8 3.png'
import icon4 from '../icons/img.icons8 4.png'

const Footer = () => {

    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scroll to top on routing
    };
    return (
        <div className='footer'>
            {/* <img id='imgSchoolLogo' src={vector1} alt="" /> */}
            <footer>

                <img src={schoolLogo} height={80} alt="" />
                <div className="footer-box">
                    <div className="links-cont">
                        <div className="det-foote def1">
                            <h1> About the School</h1>
                            <p>We is committed to providing a nurturing environment where students thrive academically, socially, and emotionally. With a rich history of excellence since 2019, we strive to shape future leaders and lifelong learners.</p>
                            <Link>Discover Our Journey →</Link>
                            <hr />
                        </div>
                        <div className="det-footer def2">
                            <h1>Explore</h1>
                            <ul>
                                <li><Link onClick={handleLinkClick} to={'/'}>Home </Link></li>
                                <li><Link onClick={handleLinkClick} to={'/about-us'}>About</Link></li>
                                <li><Link onClick={handleLinkClick} to={'/gallery'}>Gallery</Link></li>
                                <li><Link onClick={handleLinkClick} to={'/contact'}>Contact</Link></li>
                            </ul>
                        </div>
                        <div className="det-footer def3">
                            {/* <h1>Our Community</h1> */}
                            <ul>
                                <li><Link onClick={handleLinkClick} to={'/'}></Link></li>
                                <li><Link onClick={handleLinkClick} to={'/student-tools'}> </Link></li>
                                <li><Link onClick={handleLinkClick} to={'/school-events'}></Link></li>
                                <li><Link onClick={handleLinkClick} to={'/alumini'}></Link></li>
                            </ul>
                        </div>
                        <div className="det-footer def4">
                            <h1>Connect With Us</h1>
                            <strong>Address:</strong>
                            <p>Vidya Ashram Classes, Near <br /> Durga Mandir, Lalganj Road, <br /> Bagmali,  Bagmali, Hajipur - 844101</p>
                            <div className="contact-box">
                                <p><span>Phone: </span>9876543211</p>
                                <p><span>Email: </span> admin@.vidyaashramcom</p>
                            </div>
                            <div className="al-socio-icon">
                                <Link to={'https://www.facebook.com/profile.php?id=100094274325662'}><img src={icon1} alt="" /></Link>
                                <Link to={'https://www.instagram.com/vidyaashramclasses/'}><img src={icon2} alt="" /></Link>
                                <Link to={'https://www.youtube.com/@vidyaashramclasses6692'}><img src={icon3} alt="" /></Link>
                                <Link to={'https://www.youtube.com/@vidyaashramclasses6692'}><img src={icon4} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="logo-sphs">
                    {/* <img src={schoolName} height={70} alt="" /> */}
                </div>
                <br />
                <div className="copyrights">
                    <p>    © Vidya Ashram School. All Rights Reserved. Design By Knoxo</p>
                </div>

            </footer>
        </div>
    )
}

export default Footer