import React, { useState } from 'react'
import './login.css'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const [id , setID] = useState('')
    const [pass, setPass] = useState('')
    const nav = useNavigate();

    const handleLog = (e) => {
        e.preventDefault();
        if(id === 'mayank' && pass === 'mayank'){
            console.log("Login Succesfully")
            nav('/student-profile')
        }
        else{
            console.error("Wrong pass");
            
        }
    }

  return (
    <div className='login'>
        <form onSubmit={handleLog}>
            <h1>Student Login</h1>
            <input type="text" value={id} onChange={e => setID(e.target.value)} placeholder='ID' required />
            <input type="password" value={pass} onChange={e => setPass(e.target.value)}  placeholder='Password' required />
            <input type="submit" value="Submit" />
        </form>
    </div>
  )
}

export default Login