import Home from './mainComponents/Home';
import './App.css';
import Navbar from './navbar/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './foot/Footer';
import Gallery from './extComponents/gallery/Gallery';
import StudentTool from './extComponents/student/StudentTool';
import About from './mainComponents/about/About';
import Login from './access/login/Login';
import Contact from './extComponents/contact/Contact';
import Admission from './extComponents/admission/Admission';
import Facility from './extComponents/facility/Facility';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />

      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/about-us' element={<About/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/student-tools' element={<StudentTool/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/admission' element={<Admission/>}/>
        <Route path='/facilities' element={<Facility/>}/>

      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
