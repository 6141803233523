import React from 'react'
import './facility.css'

const Facility = () => {
  return (
    <div className='facility'>

        <div className="hm-fac">
            <h1>MIGLIORA IL TUO AMBIENTE DI LAVORO</h1>
            <p>Unisciti ad <span>oltre 400 imprese </span> leader che hanno scelto il nostro servizio di <span>Facility Management </span> per migliorare la qualità della vita nei loro ambienti di lavoro.</p>
        </div>

    </div>
  )
}

export default Facility